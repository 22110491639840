import axios from 'axios'
import { observable, computed, makeObservable } from 'mobx'
import { configure } from "mobx"
import FuzzySet from 'fuzzyset'
import Filter from 'bad-words'

import Tools from './tools/index'
import config from './config'


let filterBadWords = new Filter()

let baseURL = config.baseURL

configure({  enforceActions: "never", })

let api = axios.create({ baseURL, });


class appStore {

	api = api
	@observable baseURL = baseURL
	@observable redirect = ``
	@observable editor
	@observable editorIsLoading = true

	// User Profile
	@observable profile = {}
	@observable isLoggedIn = false
	@observable loginLoading = false
	
	@observable landingPageUrl = config.landingPageUrl
	@observable TOOLS = []
	@observable currentTool = []


	
	editor

	constructor(){
		makeObservable(this);
		this.init()
		// Check credits every time, and log out people who aren't authenticated
		this.api.interceptors.response.use((response) => {
			this.updateCredits(response)
			return response;
		}, (error) => {
			console.log(error)
			console.log(`error.response.statusText`,error.response.statusText)
			if (error.response && error.response.statusText === "Token Authentication Failed") {
				this.handleLogout()
			}
			if (error.response && error.response.statusText === "No Credit Remaining") {
				this.noCreditsRemainPrompt()
			}
			return Promise.reject(error);
		});
		
	}

	noCreditsRemainPrompt = () => {
		// set the browser url to the no-credits page
		window.location.pathname = "/my-profile"
	}

	init = async () => {
		try {
			this.referralTrackingCode()
			const profile = localStorage.getItem("profile")
			const token = localStorage.getItem("token")
			if (profile && token) {
				this.api.defaults.headers.common['x-access-token'] = token;
				this.profile = JSON.parse(profile)
				this.isLoggedIn = true
				//this.refreshTokenAndProfile()
			}
			//this.getTools(this.profile._id)

		} catch (err){
			console.log(err)
		}
	}

	@observable referral = ""

	referralTrackingCode = async () => {
		let referral = new URLSearchParams(window.location.search).get("referral")
		if(referral){
			this.setReferral(referral)
		} else {
			this.initReferral()
		}
	}

	setReferral = async (referral) => {
		this.referral = referral
		localStorage.setItem("referral", JSON.stringify(referral))
	}
	
	initReferral = async () => {
		const referral = localStorage.getItem("referral")
		this.referral = referral
	}

	
	loginWithDataTokenAndProfile = async (data) => {
		console.log("Profile info", data.profile)
		this.setToken(data.token)
		this.setProfile(data.profile)
		this.isLoggedIn = true
	}

	refreshTokenAndProfile = async () => {
		try {
			let data = await this.api
				.post('/user/refresh/profile')
				.then(({ data }) => data)
			if(data){
				this.setProfile(data.profile)
			}
		} catch (err) {
			console.log(err)
			this.handleLogout()
		}
	}



	setToken = async (token) => {
		this.api.defaults.headers.common['x-access-token'] = token;
		localStorage.setItem("token", token)
	}

	setProfile = async (profile) => {
		this.profile = profile
		localStorage.setItem("profile", JSON.stringify(profile))
	}

	setTools = async (tools) => {
		this.TOOLS = tools
	}


	handleLogout = () => {
		this.isLoggedIn = false
		this.profile = {}
		this.api.defaults.headers.common['x-access-token'] = ""
		localStorage.removeItem('token')
		localStorage.removeItem('profile')
	}

	@observable toolsKeyword = ""
	onChangeToolsKeyword = (e) => {
		this.toolsKeyword = e.target.value


	}

	@computed get tools() {
		// let tools = TOOLS.filter(tool => tool.title.toLowerCase().includes(this.toolsKeyword.toLowerCase()))
		const FuzzySearch = FuzzySet([...this.TOOLS.map(tool => tool.title)]);
		let fuzzyTools = FuzzySearch.get(this.toolsKeyword, 0.5)
		if(fuzzyTools && fuzzyTools.length){
			let fuzzySummary = fuzzyTools.map(fuzzyTool => fuzzyTool[1])
			if(fuzzySummary && fuzzySummary.length) {
				return this.TOOLS.filter(tool => fuzzySummary.includes(tool.title))
			}
		}
		// console.log('TOOLS in get tools', TOOLS)
		return this.TOOLS
	}

	getToolByTitle = (title) => {
		return this.TOOLS.find(tool => tool.title === title)
	}

	getToolByUrl = (url) => {
		console.log("tool by URL id", this.TOOLS.find(tool => tool.to === url))
		console.log("tool by URL type by id", typeof this.TOOLS.find(tool => tool.to === url))
		return this.TOOLS.find(tool => tool.to === url)
	}

	getTools = async (userId) => {
		const tools = new Tools()
		this.TOOLS = await tools.getTools(userId)
		return this.TOOLS
	}

	getToolById = async (toolId) => {
		console.log("tool's in getToolById", toolId)

		const response = await this.api.post('/tools/gettoolbyid', { toolId });

        this.currentTool = response.data;
		//console.log("tool  by id", this.currentTool)
		//console.log("tool by id JSON", JSON.stringify(this.currentTool))
		//console.log("tool by id type", typeof this.currentTool)

		//console.log("tool prompts",  this.currentTool.prompts)
		//console.log("tool prompts JSON",  JSON.stringify(this.currentTool.prompts))
		//console.log("tool prompts type", typeof this.currentTool.prompts)

		return this.currentTool
	}

	resetValues = async (userId) => {
		console.log("resetTools", userId)

		 const response = await this.api.post('/tools/resetTools', { userId });
		 console.log("response", response)
		 if(response){
		 	alert('Tools have been reset.')
		 }
		
		return 
	}


	@observable error = ""
	checkPrompt = ({value, attr}) => {
		if(filterBadWords.isProfane(value)){
			// eslint-disable-next-line no-throw-literal
			throw {
				success: false,
				attr,
				value: value.replace(/^\s+|\s+$/g, ''),
				message: "Unsafe content detected, please try different language"
			}
		}
		if(value){
			return {
				success: true,
				attr,
				value: value.replace(/^\s+|\s+$/g, ''),
			}
		}
	}
	checkOutput = (output) => {
		if(output){
			return output.replace(/^\s+|\s+$/g, '')
		}
		return ""
	}


	updateCredits = async (data) => {
		try {
			if(data.hasOwnProperty("data")){
				if(data.data.hasOwnProperty("credits")){
					this.profile.credits = data.data.credits
				}
				if(data.data.hasOwnProperty("creditsUsed")){
					this.profile.creditsUsed = data.data.creditsUsed
				}
			} else {
				if(data.hasOwnProperty("credits")){
					this.profile.credits = data.credits
				}
				if(data.hasOwnProperty("creditsUsed")){
					this.profile.creditsUsed = data.creditsUsed
				}
			}
		} catch (err) {
			console.log(err)
		}
	}

	@observable copyToClipboardText = ``
	copyToClipboard = (output) => {
		if (output instanceof Array) {
			output = output.join("\n")
		}
		if (!navigator.clipboard) {
			let textarea = document.getElementById('copy-textarea');
			this.copyToClipboardText = `${output}`;
			textarea.focus();
			textarea.select()
			document.execCommand('copy')
			return;
		}
		navigator.clipboard.writeText(output).then(function() {
			console.log('Async: Copying to clipboard was successful!');
		}, function(err) {
			console.error('Async: Could not copy text: ', err);
		});
	}

	@observable sendToGoogleDocText = ``
	sendToGoogleDoc = async (output, doc) => {
		if (output instanceof Array) {
			output = output.join("\n")
		}

		// Get the profile object from local storage
		const profile = JSON.parse(localStorage.getItem('profile'));
		let docId = '';

		if (doc == 'Target Market'){
			 docId = profile.targetMarketDocId;
			console.log(docId);
		}

		if (doc == 'Transformation Offer'){
			 docId = profile.offerDocId;
			console.log('Transformation Offer',docId);
		}

		if (doc == 'Funnel Blueprint'){
			 docId = profile.funnelDocId;
			console.log('Funnel Blueprint:', docId);
		}

		if (doc == 'Sales Process'){
			 docId = profile.salesDocId;
			console.log('Sales Process', docId);
		}

		let folderId = profile.folderId




	
		try {
			// Replace '/api/send-to-doc' with the actual API endpoint.
			const response = await this.api.post('/ai/sendtodoc', { output, folderId, docId });
	
			if (response.status === 200) {
				console.log('Async: Sending to google doc was successful!');
				alert('Async: Sending to google doc was successful!');


			} else {
				console.error('Async: Sending to google doc failed: ', response.status);
			}
		} catch (error) {
			console.error('Async: Sending to google doc failed: ', error);
		}
	}

	@observable feedback = ``
	reportToFeedback = (output) => {
		this.redirect = "/my-profile/feedback"
		this.feedback = `${output}`
		setTimeout(()=>{ this.redirect = "" }, 50)
	}

	
}


export default appStore