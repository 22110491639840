import React, { Component } from 'react';
import {
    PencilIcon,
    ClockIcon,
    InformationCircleIcon,
    DuplicateIcon,
  } from '@heroicons/react/outline'

  import {Helmet} from "react-helmet";

import Header from '../Components/Header'
import Body, { Grid, Col } from '../Components/Body'
import Button from '../Components/Button'
import Output from '../Components/Output'
import Chat from '../Components/Chat'
import MCEEditor from '../Components/MCEEditor'



import Countdown from 'react-countdown';
import { withRouter } from 'react-router-dom'

import { observable, makeObservable, computed,  } from 'mobx'
import { observer, inject,  } from 'mobx-react'

import EntryTabs from '../Components/EntryTabs'
import EntryPrompt from '../Components/EntryPrompt'
import EntryInput from '../Components/EntryInput'
import EntryN from '../Components/EntryN'
import ReactPlayer from "react-player";  // Import React Player


import Filter from 'bad-words'
import AutonomousTRE from '../Components/AutonomousTRE';
        //const ReactEditorJS = createReactEditorJS()

let filterBadWords = new Filter()

@withRouter
@inject('store')
@observer
class Tool extends Component {

    @observable tool = {}
    @observable nextTool = {}
	@observable prompts = []
	@observable currentPrompt = 0
	@observable currentOption = "Start Using"
    @observable error = ""
    @observable airesponse = ""
    @observable output = ""
	@observable outputs = []
    @observable code = ""
    @observable currentValue = ""
    @observable loading = false
	@observable date = Date.now() + 1000
    countdown = []

    
    
    constructor(props) {
        super(props);
        makeObservable(this);

        const path = window.location.pathname; // Get the current URL path
        const segments = path.split('/'); // Split the path into segments

        // Extract the last segment as the toolId
        const toolId = segments[segments.length - 1];
        console.log("tool's id in construstor in Tools", toolId)

        this.props.store.getToolById(toolId)
            .then( (tool) => {
                this.tool = tool;

                this.props.store.getToolById(this.tool.nextStep).then(async (nextTool) => {
                    console.log("getNextTool please", JSON.stringify(nextTool))
                    this.nextTool = nextTool

                    if(this.tool.isAutonomous == true && this.tool.isCompleted == false){
                        console.log('Autonomous mode... ', this.tool.isAutonomous);

                        //Wait 3 seconds
                        await this.delay(5000); // Wait for 5 seconds

                        const success = await this.onAutonomousMode(); // await the result of onAutonomousMode
                        await this.delay(10000); // Wait for 5 seconds

                        if (success) {
                            this.navigateToNextStep();
                        }

                    }

                }).catch((error) => {
                    console.log('Error fetching Next tool:', error);
                });
    
                if(!this.tool) {
                    window.location.href = '/';
                } else {
                    //console.log(' tool prompts From Response', this.tool.prompts);
                    //console.log(' tool prompts From Response JSON', JSON.stringify(this.tool.prompts));
                    //console.log('tool prompts From Response type:', typeof this.tool.prompts);
                   
    
                    if(Array.isArray(this.tool.prompts)) {
                        this.prompts = [...this.tool.prompts];

                        //console.log('Tool AI Response', this.tool.output.airesponse);
                        //console.log('This Prompts', this.prompts);
                         // required for mobx to pick up deeply nested value 
                         this.currentValue = this.prompts[this.currentPrompt].prompts[0].value

                        this.output = this.tool.output.airesponse;
                    } else {
                        console.log('Error: tool.prompts is not iterable.');
                    }
                }
            })
            .catch((error) => {
                console.log('Error fetching tool:', error);
            });

    }

    delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    navigateToNextStep = () => {

		// Navigate to the next step URL
		this.props.history.push(`${this.nextTool.to}/${this.nextTool._id}`);
		this.props.history.go(`${this.nextTool.to}/${this.nextTool._id}`);
	};
    

    handleCurrentPrompt = (val) => {
		this.currentPrompt = val
	}

    @computed get isGenerateButtonDisabled(){

        

        if(this.loading){
            return true
        }
       
        return false
    }

    @computed get disabled(){

        if(this.prompts[this.currentPrompt].prompts[0].value.length < 1){
            return true
        }

        
        // this.prompts[this.currentPrompt].prompts[promptIndex].value
        return false
    }

    @computed get isMinLength() {
		
		if(!this.props.prompt.min){
			return false
		}
		if(!this.props.prompt.type === "number"){
			return false
		}
		
		return false
	}

    

    checkMinimumPrompts = () => {

        let shouldReturn = false
       
        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            if(prompt.min){
                if(prompt.value.length < prompt.min){
                    shouldReturn = true
                    prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
                }
            }
        })

        return shouldReturn
    }


    clearExampleTimeout = []

    onStartUsing  = async () => {
        this.loading = false
        this.error = ""
        this.clearExampleTimeout.forEach((item,index) => {
            clearTimeout(this.clearExampleTimeout[index])
        })
        this.currentOption = "Start Using"
    }



    sanitizeAllPrompts = () => {
        this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
            if(!prompt.value){
                return false;
            }
            if(prompt.type === "number"){
                return false;
            }
           
            prompt.value = prompt.value.trim()

            if(filterBadWords.isProfane(prompt.value)){
                prompt.error = "Unsafe content , please try different language"
                throw Error("Unsafe content")
            }
        })
	}

    contentFilterFlagged = async (response) => {
        this.error = response.message

        this.date = Date.now() + 5000
        this.countdown.forEach(countdown => {
            if(countdown){
                countdown.stop()
                countdown.start()
            }
        })
        this.loading = false
    }

    checkOutput = (output) => {
		if(output){
			output = output.replace(/^\s+|\s+$/g, '')
            // output = output.replace(/\s{2,}/g, ' ')
		}
		return output
	}

    @computed get language() {
        let language = "";
        if (this.prompts.length > 0 && this.prompts[this.currentPrompt] && this.prompts[this.currentPrompt].prompts) {
            this.prompts[this.currentPrompt].prompts.forEach(prompt => {
                if (prompt.attr === "language") {
                    language = `${prompt.value}`
                }
            });
        }
        return language;
    }
    

    updateToolswithAiResponse = (toolId, newAiResponse) => {
        const updatedTools = this.props.store.TOOLS.map((tool) => {
          if (tool._id === toolId) {
            // Create a new object with updated airesponse property
            return {
              ...tool,
              output: {
                ...tool.output,
                airesponse: newAiResponse,
              },
            };
          }
          return tool; // Return the unchanged tool object
        });

        return updatedTools;
    }

    onAutonomousMode = () => {
        return new Promise(async (resolve, reject) => {
          try {
            this.error = "";
            this.output = "";
            this.code = "";
            this.outputs = [];
            this.loading = true;
      
            let checkMinimumPrompts = this.checkMinimumPrompts();
            if (checkMinimumPrompts) {
              this.loading = false;
              return resolve(false);
            }
            let postObj = {} 

            this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
                postObj[prompt.attr] = prompt.value
            })

            postObj.currentPrompt = this.prompts[this.currentPrompt].title
            if(this.prompts[this.currentPrompt].n){
                postObj.n = this.prompts[this.currentPrompt].n
            }

            if (this.tool.api.includes('sendprompter')) {
                postObj.businessName = JSON.parse(localStorage.getItem('profile')).company
            }
            postObj.toolId = this.tool._id

            let response = await this.props.store.api
                .post(this.tool.api, postObj)

            if(!response.data.success){
                this.contentFilterFlagged(response.data)
                return resolve(false);
            }

            if(response.data.output){
                this.output = this.checkOutput(response.data.output)
                //Updaate tools with new Reponse
                const airesponseupdate= this.updateToolswithAiResponse(this.tool._id, this.output);
                this.props.store.TOOLS = airesponseupdate;

            }

            if(response.data.code){
                this.code = response.data.code
            }

            if(response.data.outputs){
                this.outputs = response.data.outputs
            }
            
            this.date = Date.now() + 10000
            this.countdown.forEach(countdown => {
                if(countdown){
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false      
            // Instead of directly setting values, you can use resolve to return the result
            resolve(true);
          } catch (error) {
            this.countdown.forEach(countdown => {
                if(countdown){
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false
            reject(error);
          }
        });
      };
      


    onGenerateClick = async () => {
        try {
            this.error = ""
            this.output = ""
            this.code = ``
            this.outputs = []
            this.loading = true

            let checkMinimumPrompts = this.checkMinimumPrompts()
            if(checkMinimumPrompts){
                this.loading = false
                return false
            }
            // this.sanitizeAllPrompts()

            let postObj = {} 

            this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
                postObj[prompt.attr] = prompt.value
            })

            postObj.currentPrompt = this.prompts[this.currentPrompt].title

            if(this.prompts[this.currentPrompt].n){
                postObj.n = this.prompts[this.currentPrompt].n
            }

            if (this.tool.api.includes('sendprompter')) {
                postObj.businessName = JSON.parse(localStorage.getItem('profile')).company
            }
            postObj.toolId = this.tool._id

            let response = await this.props.store.api
                .post(this.tool.api, postObj)

            if(!response.data.success){
                this.contentFilterFlagged(response.data)
                return false
            }

            if(response.data.output){
                this.output = this.checkOutput(response.data.output)
                //Updaate tools with new Reponse
                const airesponseupdate= this.updateToolswithAiResponse(this.tool._id, this.output);
                this.props.store.TOOLS = airesponseupdate;

            }

            if(response.data.code){
                this.code = response.data.code
            }

            if(response.data.outputs){
                this.outputs = response.data.outputs
            }
            
            this.date = Date.now() + 10000
            this.countdown.forEach(countdown => {
                if(countdown){
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false
        } catch (error){
            console.log(error)
            this.countdown.forEach(countdown => {
                if(countdown){
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false
        }
    }

        render() {



            return(
                <>
                <Helmet>
                    <title>{`${this.tool.title} Tool - OpenAI Template`}</title>
                </Helmet> 
                <Header 
                    title={this.tool.title}
                    desc={this.tool.desc}
                    Icon={this.tool.Icon}
					fromColor={this.tool.fromColor}
					category={this.tool.category}
                    
                 
                    currentOption={this.currentOption}
                />

                <Body>
                
                        {this.tool.aiModule == 'editor' && 
                        <Grid >
                            <Col span="12">
                                <MCEEditor props={this.props} tool = {this.tool}/>
                            </Col>
                        </Grid>
                        }
                        {this.tool.aiModule == 'chatbot' && 
                        <Grid >
                            <Col span = "12">
                                <div className="intro-video-wrapper mb-4">
						            <ReactPlayer url={this.tool.video}
                                        playing={false} 
                                        controls={true} 
                                        width="100%" />
			                    </div>
                            </Col>
                            <Col span="12">
                                <Chat props={this.props} tool = {this.tool}/>
                            </Col>
                        </Grid>
                        }
                        {this.tool.aiModule == 'prompt' && 
                        <Grid>
                            {this.tool.video !== "" && 
                            <Col span = "12">
                                <div className="intro-video-wrapper mb-4">
						            <ReactPlayer url={this.tool.video}
                                        playing={false} 
                                        controls={true} 
                                        width="100%" />
			                    </div>
                            </Col>
                            }
                            {this.tool.isAutonomous == true && 
                            <Col span="12">
                                <AutonomousTRE />
                            </Col>
                            }

                            <Col span="12">

                                {/* <EntryTabs
                                    prompts={this.prompts}
                                    currentPrompt={this.currentPrompt}
                                    onChange={this.handleCurrentPrompt}
                                /> */}

                                {this.prompts.map((prompt, index) => 
                                    <EntryPrompt
                                        prompt={prompt} 
                                        key={index} 
                                        index={index}
                                        disabled={this.disabled}
                                        currentPrompt={this.currentPrompt}
                                    >
                                        {prompt.prompts.map((promptInput, index) => 
                                            <EntryInput
                                                prompt={promptInput}
                                                key={index}
                                                language={this.language}
                                                index={index}
                                                disabled={this.disabled}
                                            />

                                           // <MCEEditor key={index} props={this.props} prompt={promptInput} tool = {this.tool}/>


                                            )}
                                            



                                    <div className="md:flex">
                                        <Countdown 
                                            ref={countdown => this.countdown[index] = countdown} 
                                            date={this.date} 
                                            renderer={props => 
                                            <Button 
                                                title={props.total ? `Timeout ${props.total/1000} secs` : "Perform Request"}
                                                disabled={props.total || this.isGenerateButtonDisabled}
                                                Icon={props.total ? ClockIcon : this.currentValue ? DuplicateIcon : PencilIcon} 
                                                onClick={this.onGenerateClick} 
                                            />} 
                                        /> 
                                        <EntryN 
                                            prompts={this.prompts} 
                                            currentPrompt={this.currentPrompt}
                                        />
                                    </div>



                                    {this.error && <div className="mt-4"><label 
                                        className={`${this.error ? "text-red-400" : "text-gray-400"} font-medium transition-all`}>
                                            {this.error}
                                    </label></div>}

                                    </EntryPrompt>
                                )}

                               
                            </Col>
                            <Col span="12">
                             <Output 
                                    title={this.tool.output.title}
                                    desc={this.tool.output.desc}
                                    outputType={this.tool.output.type}
                                    isHuman={this.tool.isHuman}
                                    manualTaskInProgress={this.tool.manualTaskInProgress}
                                    Icon={this.tool.output.Icon || this.tool.Icon}
                                    fromColor={this.tool.fromColor}
									toColor={this.tool.toColor}
                                    nextTool = {this.nextTool}
          
                                    loading={this.loading}
                                    output={this.output}
                                    airesponse= {this.output}
									outputs={this.outputs}
                                    code={this.code}
                                    language={this.language}
                                    nextStep={this.tool.nextStep}
                                    toolId = {this.tool._id}
                                    outputsColor={this.tool.output.color}
                                    OutputsIcon={this.tool.output.Icon}
                                    docType = {this.tool.docType}
                            /> 
                        </Col> 
                    </Grid> 
                    }
                </Body>
            </>
        )
    }
}


export default withRouter(Tool)