import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { observable,computed,  } from 'mobx'
import MainBody from './Components/Body'
import {Helmet} from "react-helmet";

import ReactPlayer from "react-player";  // Import React Player
import Button from './Components/Button'
import {
    ClockIcon,
  } from '@heroicons/react/outline'



import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class Body extends Component {

	@observable error = ""
    @observable output = ""
    @observable code = ""
	@observable outputs = []
    @observable loading = false
	@observable prompts = []
	@observable currentPrompt = 0

	@observable tool = {}
    @observable nextTool = {}
	@observable currentOption = "Start Using"
    
    @observable airesponse = ""
    @observable currentValue = ""
	@observable date = Date.now() + 1000
    countdown = []

	
	

	componentDidMount() {
		const profile = JSON.parse(localStorage.getItem("profile"))
		const userId = profile._id;
		console.log("UserId", userId)
		this.props.store.getTools(userId);

	  }

	@computed get permissions() {
		//console.log('this.props.store.tools', this.props.store.tools)
		return this.props.store.tools.filter(tool=>
			tool.permissions.some(r=> this.props.store.profile.permissions.includes(r))
		)
	}

	sortToolsByOrder(tools) {
		return tools.sort((a, b) => a.order - b.order);
		//return tools;

		
	}
	

	runAllTools =  () => {
		const { tools } = this.props.store;
		const orderedTools = [
			...this.offerGeneration,
			...this.audienceGeneration,
			...this.leadGeneration,
			...this.leadNurturing,
			...this.salesConversation
		];

		console.log('Ordered Tools:', orderedTools)

		
	
		for (const tool of orderedTools) {

			if (tool.api) {  // Assuming that the tool has an 'api' property which is an endpoint URL
				if (!tool.isCompleted) {

					if(tool.isAutonomous === true){
						//Loop through each tool
						//Stop if tool
						console.log('Tool', tool.api)
						console.log('Title', tool.title)
						console.log('completed', tool.isCompleted)
						console.log('isAutonomous', tool.isAutonomous)
						console.log('tool_id', tool._id)

						this.props.store.getToolById(tool._id).then( async (tool) => {

							console.log("tool", tool)
		
							// this.props.store.getToolById(this.tool.nextStep).then(async (nextTool) => {
						// 	console.log("getNextTool please", JSON.stringify(nextTool))
						// 	this.nextTool = nextTool
		
						// 	if(this.tool.isAutonomous == true && this.tool.isCompleted == false){
						// 		console.log('Autonomous mode... ', this.tool.isAutonomous);
		
						// 		//Wait 3 seconds
						// 		await this.delay(5000); // Wait for 5 seconds
		
						// 		const success = await this.onAutonomousMode(); // await the result of onAutonomousMode
						// 		await this.delay(10000); // Wait for 5 seconds
		
						// 		if (success) {
						// 			this.navigateToNextStep();
						// 		}
		
						// 	}
		
							// }).catch((error) => {
							// 	console.log('Error fetching Next tool:', error);
							// });
			
					
			
							if(Array.isArray(tool.prompts)) {
								this.prompts = [tool.prompts];
		
								//console.log('Tool AI Response', this.tool.output.airesponse);
								//console.log('This Prompts', this.prompts);
								 // required for mobx to pick up deeply nested value 
								 this.currentValue = tool.prompts[this.currentPrompt].prompts[0].value
								 this.output = tool.output.airesponse;

								 //Wait 3 seconds
								 await this.delay(5000); // Wait for 5 seconds
								 const success = await this.onAutonomousMode(tool); // await the result of onAutonomousMode

								 //const success = await this.onAutonomousMode(tool); // await the result of onAutonomousMode
								 await this.delay(10000); // Wait for 10 seconds
							} else {
								console.log('Error: tool.prompts is not iterable.');
							}
						
						})
						.catch((error) => {
							console.log('Error fetching tool:', error);
						});

						//this.onAutonomousMode(tool)


					}else if(tool.isAutonomous === false){
						console.log("Automous is false", tool.title)
						const updateToolInprogress= this.updateTooltoInprogress(tool._id);
						this.props.store.TOOLS = updateToolInprogress

						//Stop Loop
						break;
					}
					

				}
			}
		};
	}



	onAutonomousMode = (tool) => {
        return new Promise(async (resolve, reject) => {
          try {
			console.log("");
            this.error = "";
            this.output = "";
            this.code = "";
            this.outputs = [];
            this.loading = true;
      
            let checkMinimumPrompts = this.checkMinimumPrompts(tool);

            // if (checkMinimumPrompts) {
            //   this.loading = false;
            //   return resolve(false);
            // }
            let postObj = {} 

             tool.prompts[this.currentPrompt].prompts.forEach((prompt) => {
                 postObj[prompt.attr] = prompt.value
             })

             postObj.currentPrompt = tool.prompts[this.currentPrompt].title

             if(tool.prompts[this.currentPrompt].n){
                postObj.n = this.prompts[this.currentPrompt].n
            }

            if (tool.api.includes('sendprompter')) {
                postObj.businessName = JSON.parse(localStorage.getItem('profile')).company
            }

            postObj.toolId = tool._id
			console.log("Post Obj", postObj);


            let response = await this.props.store.api
                 .post(tool.api, postObj)

             if(!response.data.success){
                 //this.contentFilterFlagged(response.data)
				 const updateToolInprogress= this.updateTooltoInprogress(tool._id);
                  this.props.store.TOOLS = updateToolInprogress

                 return resolve(false);
             }

             if(response.data.output){
                 //this.output = this.checkOutput(response.data.output)
                 //Updaate tools with new Reponse

                  const airesponseupdates= this.updateToolswithAiResponse(tool._id);
                  this.props.store.TOOLS = airesponseupdates
            }

            if(response.data.code){
                this.code = response.data.code
            }

            if(response.data.outputs){
                this.outputs = response.data.outputs
            }
            
            // this.date = Date.now() + 10000
            // this.countdown.forEach(countdown => {
            //     if(countdown){
            //         countdown.stop()
            //         countdown.start()
            //     }
            // })

            this.loading = false      
            // Instead of directly setting values, you can use resolve to return the result
            resolve(true);
          } catch (error) {
            this.countdown.forEach(countdown => {
                if(countdown){
                    countdown.stop()
                    countdown.start()
                }
            })
            this.loading = false
            reject(error);
          }
        });
      };

	  updateTooltoInprogress = (toolId) => {
        const updatedTools = this.props.store.TOOLS.map((tool) => {
          if (tool._id === toolId) {
            // Create a new object with updated airesponse property
            return {
              ...tool,
			  isCompleted: "inprogress"
            };
          }
          return tool; // Return the unchanged tool object
        });

        return updatedTools;
    }

	  updateToolswithAiResponse = (toolId, newAiResponse) => {
        const updatedTools = this.props.store.TOOLS.map((tool) => {
          if (tool._id === toolId) {
            // Create a new object with updated airesponse property
            return {
              ...tool,
              output: {
                ...tool.output,
                airesponse: newAiResponse,
              },
			  isCompleted: true
            };
          }
          return tool; // Return the unchanged tool object
        });

        return updatedTools;
    }
	
	  checkMinimumPrompts = (tool) => {

        let shouldReturn = false
       
        tool.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            if(prompt.min){
                if(prompt.value.length < prompt.min){
                    shouldReturn = true
                    prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
                }
            }
        })

        return shouldReturn
    }

	delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }


	

	@computed get offerGeneration() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Offer Generation'))
	}

	@computed get audienceGeneration() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Audience Generation'))
	}


	@computed get leadGeneration() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Lead Generation'))
	}

	@computed get leadNurturing() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Lead Nurturing'))
	}

	@computed get salesConversation() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Sales Conversation'))
	}

	@computed get onboarding() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Onboarding'))
	}

	@computed get fullfillmentSupport() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Fullfillment Support'))
	}

	@computed get productDevelopment() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Product Development'))
	}

	@computed get referralAndUpsell() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Referral and Upsell'))
	}

	@computed get humanResources() {
		return this.sortToolsByOrder(this.permissions.filter(tool => tool.category === 'Human Resources'))
	}



	render() {
	return (

		<>
			{/* <Helmet>
				<title>{`Tre`}</title>
			</Helmet> */}
			
			<MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
			<div className="intro-video-wrapper mb-4">
						<ReactPlayer url="https://upclub.s3.amazonaws.com/TheStrategy_ALTERED.mp4" playing={false} controls={true} width="100%"
/>
			</div>
			<div className="flex justify-end items-center">
				<Button 
                                                title="Complete Entire Plan"
                                                Icon={ClockIcon} 
                                                onClick={this.runAllTools} 
												className="bg-green-500 text-white hover:bg-green-600" 
                                            />
			</div>
			

			{this.offerGeneration.length ? <>
				<Title title="Craft an irresistible offer" />
				<Grid>
					{this.offerGeneration.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.audienceGeneration.length ? <>
				<Title title=" Create 60 Second Offer Ad" />
				<Grid>
					{this.audienceGeneration.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 							
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.leadGeneration.length ? <>
				<Title title="Get Appointments" />
				<Grid>
					{this.leadGeneration.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
				</> : null}

			{this.leadNurturing.length ? <>
				<Title title="Nurture Leads to Book Even More Appointments" />
				<Grid>
					{this.leadNurturing.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.salesConversation.length ? <>
				<Title title="Close Deals" />
				<Grid>
					{this.salesConversation.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.onboarding.length ? <>
				<Title title="Onboarding" />
				<Grid>
					{this.onboarding.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.fullfillmentSupport.length ? <>
				<Title title="Fullfillment Support" />
				<Grid>
					{this.fullfillmentSupport.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.productDevelopment.length ? <>
				<Title title="Product Development" />
				<Grid>
					{this.productDevelopment.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.humanResources.length ? <>
				<Title title="Human Resources" />
				<Grid>
					{this.humanResources.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

			{this.referralAndUpsell.length ? <>
				<Title title="Referral and Upsell" />
				<Grid>
					{this.referralAndUpsell.map((tool, index) => 
						<Tool 
							key={index}
							toolId={tool._id}
							isCompleted={tool.isCompleted}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							isHuman={tool.isHuman}
							manualTaskInProgress={tool.manualTaskInProgress}
						/>)} 
				</Grid>
				<Divider />
			</> : null}

</MainBody>
</>)
}
  }

export const Divider = () => <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12"> <div></div>
<div></div></div>

export const Title = ({ title }) => <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">
{title}
</h2>

export const Grid = ({ children }) => <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-1 xl:grid-cols-1 ">{children}</div>


export const Tool = ({ Icon, toolId, title, desc, to, group, fromColor, isCompleted, isHuman, manualTaskInProgress, toColor }) => (
	<Link to={`${to}/${toolId}` || "/"} className="flex relative">
	  <div className="bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105 hover:text-black">
		<div className="p-4 flex items-start">
		{/* AI States */}
		{isCompleted == true && isHuman == false  && (
          <img src="/completed-tre.png" alt="Checkmark" className="mr-4 h-20 w-20" />
        )}
		{isCompleted == "inprogress" && (
          <img src="/inprogress-tre.png" alt="Not Checkmark" className="mr-4 h-20 w-20" />
        )}	
		{isCompleted == false && isHuman == false && (
          <img src="/not-completed-tre.png" alt="Not Checkmark" className="mr-4 h-20 w-20" />
        )}

		{/* Human States */}
		{manualTaskInProgress ==true &&(
          <img src="/inprogress-human.png" alt="Checkmark" className="mr-4 h-20 w-20" />
        )}
		{isCompleted == false && isHuman==true && manualTaskInProgress ==false &&(
          <img src="/not-completed-human.png" alt="Not Checkmark" className="mr-4 h-20 w-20" />
        )}
		{isCompleted == true && isHuman == true && manualTaskInProgress ==false &&(
          <img src="/completed-human.png" alt="Checkmark" className="mr-4 h-20 w-20" />
        )}
		
		

		<div className="flex flex-col">
			<div className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>
			  {group || "New"}
			</div>
			<div href="#" className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">
			  {title}
			</div>
			<p className="mt-1 pr-1 text-sm">{desc}</p>
		  </div>
		</div>
	  </div>
	</Link>
  );
  

// export const Tool = ({ Icon, toolId,title, desc, to, group, fromColor, toColor }) => 
// <Link to={`${to}/${toolId}` || "/"} className="flex relative ">
// 	<div className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black`}>
//   <div className="p-4">
// 	<div className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>{group || "New"}</div>
// 	<div href="#" className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
// 	<p className="mt-1 pr-1 text-sm ">{desc} </p>
//   </div>
// </div>
// </Link>



export default Body