import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from 'axios';
import './Chat.css';
import appStore from '../store'

import "@fortawesome/fontawesome-free/css/all.min.css";
const Logo = () => (

	<img
    src="/tre.png"
    alt="Logo"
    className="w-10 h-10 inline-block"
  />
	
)



function Chat({ props, tool}) {

 // const [messages, setMessages] = useState([{ role: 'assistant', content: tool.chatbot.initialMessage}]);
 const [messages, setMessages] = useState([
  { role: 'assistant', content: tool.chatbot.initialMessage },
  ...(tool.chatbot.chatInputs
    ? tool.chatbot.chatInputs.map((input) => ({
        role: 'assistant',
        content: input.inputMessage + input.value,
      }))
    : []),
]);

  const [inputValue, setInputValue] = useState("");
  const chatContainer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const myStore = new appStore()



  const handleInput = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  //const [lastAnimatedIndex, setLastAnimatedIndex] = useState(-1);



  // Move lastAnimatedIndex into the useRef hook, since it doesn't need to trigger a re-render
  const lastAnimatedIndex = useRef(-1);


  const handleSubmit = async (e) => {
      e.preventDefault();

      // Send input to API to generate response

      // { role: 'user', content: userMessage.trainingDescription }
      // { role: 'assistant', content: userMessage.trainingDescription }
      setMessages([...messages, { role: 'user', content: inputValue, }]);
      setInputValue("");


      console.log('messages', messages);

      let postObj = {messages: messages, inputValue, currentPrompt: "Write a Service Description", toolId: tool._id}
      
      setIsLoading(true); // Set the loading state to true

      let response = await props.store.api
                .post(tool.api, postObj)

      console.log('response', response.data.message);

      setIsLoading(false); // Set the loading state to false


    // Set the last animated index to the index of the last assistant message
    lastAnimatedIndex.current = messages.reduce((lastIndex, message, index) => {
      return message.role === "assistant" ? index : lastIndex;
    }, -1);


    const responseText = response.data.message.replace(/\n/g, "<br/>");
    const botMessage = {
    content: responseText,
    role: "assistant",
    //isHtml: true,
    //isTyping: true, // Set isTyping to true for the botMessage
    //showMessage: false, // Add a new property showMessage
  };
  
  setMessages([...messages, { content: inputValue, role: "user" }, botMessage]);
  setInputValue("");

  // Trigger the typing animation
  setIsTyping(true);

  const typingDelay = 2000; // Set the typing delay (in milliseconds)
    setTimeout(() => {
      // setMessages((prevMessages) =>
      //   prevMessages.map((msg, idx) =>
      //     idx === prevMessages.length - 1 ? { ...msg, showMessage: true } : msg
      //   )
      // );
    }, typingDelay);


  };

  useEffect(() => {
    // If isTyping is true, start the typing animation
    if (isTyping) {
      const typingDelay = 2000; // Set the typing delay (in milliseconds)
      setTimeout(() => {
        setIsTyping(false);
      }, typingDelay);
    }
  }, [isTyping]);

  const handleExport = (text) => {
    // Code to export the message to Google Docs goes here
    console.log(`Exporting message: "${text}" to Google Docs`);
    const googleDocOutput = text.replace(/<br\s*\/?>/gi, '\n');
    //const text = html.replace(/<br\s*\/?>/gm, '\n');
    myStore.sendToGoogleDoc(googleDocOutput, tool.docType) 

  };

  useEffect(() => {
    chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
  }, [messages]);

  const LoadingAnimation = () => (
    <div
      style={{
        alignSelf: "flex-start",
        margin: "10px",
        padding: "10px",
        maxWidth: "100%",
        wordWrap: "break-word",
        borderRadius: "10px",
        backgroundColor: "#fff",
        color: "black",
        display: "flex",
      }}
    >
      <Logo />
      <div style={{ flex: 1 }}>
        <span className="loading-dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </div>
    </div>
  );
  


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "500px",
      }}
    >
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "10px",
        }}
        ref={chatContainer}
      >

<div

    style={{
      margin: "10px",
      padding: "10px",
      maxWidth: "100%",
      wordWrap: "break-word",
      borderRadius: "10px",
      alignSelf: "flex-start",
      backgroundColor: "#FFE5AF",
      color: "black",
      display: "flex",
    }}
  >
    <div
      style={{
        width: "40px",
        height: "40px",
        marginRight: "10px",
        borderRadius: "50%",
        backgroundColor: "#f0f0f0",
      }}
    >
      
        <Logo />
    </div>
    <div style={{ flex: 1 }}>

    <span
      dangerouslySetInnerHTML={{ __html: "Please type 'Finalize' when I, T.R.E., delivers the final result" }}
      className={"message"}
    ></span>
    </div>
  </div>
{messages.map((message, index) => (
  <div
    key={index}

    style={{
      margin: "10px",
      padding: "10px",
      maxWidth: "100%",
      wordWrap: "break-word",
      borderRadius: "10px",
      alignSelf: message.role === 'user' ? "flex-end" : "flex-start",
      backgroundColor: message.role === 'user' ? "#007bff" : "#fff",
      color: message.role === 'user' ? "white" : "black",
      display: "flex",
    }}
  >
    <div
      style={{
        width: "40px",
        height: "40px",
        marginRight: "10px",
        borderRadius: "50%",
        backgroundColor: message.role === 'user' ? "#007bff" : "#f0f0f0",
      }}
    >
      {message.role === 'user' ? (
        <i className="fa fa-user" style={{ color: "white" }} />
      ) : (
        <Logo />
      )}
    </div>
    <div style={{ flex: 1 }}>

    <span
      dangerouslySetInnerHTML={{ __html: message.content }}
      className={
        message.role === "assistant" && message.isTyping && !message.showMessage
          ? "message animate typing"
          : "message"
      }
    ></span>


  {/* {message.isHtml ? (
    <span
      dangerouslySetInnerHTML={{ __html: message.content }}
      className={
        message.role === "assistant" && message.isTyping && !message.showMessage
          ? "message animate typing"
          : "message"
      }
    ></span>
  ) : (
    <span
      className={
        message.role === "assistant" && message.isTyping && !message.showMessage
          ? "message animate typing"
          : "message"
      }
    >
      {message.content}
    </span>
  )} */}
</div>

    {message.role === 'assistant' && (
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => handleExport(message.content)}
      >
        <i
          className="fa fa-file"
          style={{ color: "grey", fontSize: "20px" }}
          title="Export to Google Docs"
        />
      </div>
    )}
  </div>
))}
{isLoading && <LoadingAnimation />}

    </div>
    <form
        style={{
          display: "flex",
          borderTop: "1px solid #ccc",
          padding: "10px",
          position: "sticky",
          bottom: "0",
          backgroundColor: "white",
          zIndex: "1",
        }}
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          placeholder="Type your message here"
          value={inputValue}
          onChange={handleInput}
          style={{
            flex: 1,
            padding: "10px",
            fontSize: "16px",
            border: "none",
            borderBottom: "1px solid #ccc",
          }}
        />
        <button
          type="submit"
          style={{
            padding: "10px",
            fontSize: "16px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          Send
        </button>
      </form>
    </div>
  );
}

export default Chat;