import React, { Component } from 'react'
import { CheckIcon,  } from '@heroicons/react/outline'
import { observer, inject } from 'mobx-react'


@inject("store")
@observer
class AutonomousTRE extends Component {
	render() {

		//const { currentPrompt, disabled, index } = this.props 
		//let hidden = currentPrompt === index ? false : true

		return (
			<div className={`align-bottom bg-white md:rounded-md text-left overflow-hidden transform transition-all sm:align-middle transition hover:shadow-md shadow-2xl focus:shadow-2xl md:mb-8  `}>

				<div className="px-6 py-6" style={{backgroundColor: "#6EE7B7"}}>

					<div className="flex items-center">

						<div className={`flex-shrink-0 inline-flex items-center justify-center md:h-20 md:w-20 h-12 w-12 rounded-full bg-green-300 sm:mx-0 sm:h-10 sm:w-10  bg-green-300`}>
						<img src="/completed-tre.png" alt="Checkmark" className="mr-4 h-20 w-20" />

							
							{/* <CheckIcon 
								className={`h-3 w-3 md:h-6 md:w-6 text-green-700 text-green-700`} aria-hidden="true" /> */}

						</div>

						<div className="mt-0 ml-4 text-left">
							<div as="h3" className="text-lg leading-6 font-medium text-gray-900">

								I am in autonomous mode. Let me handle this task for you.

							</div>

							<p className="text-sm text-gray-500">

								Go get a cup of coffee while I finish this tasks for you.

							</p>

						</div>

					</div>
				</div>
			</div>
		)
	}
}

export default AutonomousTRE;