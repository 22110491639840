import { ThemeProvider } from 'styled-components';
import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

import AppStore from './store';
import colors from 'tailwindcss/colors';
import Header from './Header';
import Search from './Search';
import Dashboard from './Dashboard';
import TaskList from './TaskList';
import Tool from './Core/Tool';
import Chat from './Core/Chat';
import Login from './Login/Login';
import Profile from './Profile/';
import LoginSuccess from './Login/Success';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './App.scss';

if (!window.store) {
  window.store = new AppStore();
}

@observer
class App extends Component {
  render() {
    return (
      <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
            {window.store.redirect ? (
              <Redirect to={window.store.redirect} />
            ) : null}
            {window.store.isLoggedIn ? (
              <>
                {/* Logged in */}
                {window.store.profile.status ? (
                  <>
                    {/* Logged in with plan */}
                    <Switch>
                      <Route path="/writing/document">
                        <div />
                      </Route>
                      <Route component={Header} />
                    </Switch>
                    <Switch>
                      <Route path="/" exact component={Dashboard} />
                      <Route path="/search" exact component={Search} />
                      <Route path="/tasklist" exact component={TaskList} />
                      <Route path="/ai/:business/:page/:id" component={Tool} />
                      <Route path="/ai/code/debugging" component={Chat} />
                      <Route path="/my-profile" component={Profile} />
                      <Route path="/signup/failed" component={Profile} />
                      <Route path="/signup/success" component={LoginSuccess} />
                    </Switch>
                  </>
                ) : (
                  <>
                    {/* Logged in but no plan */}
                    {/* Render your desired component or redirect */}
                  </>
                )}
              </>
            ) : (
              <>
                {/* Not logged in */}
                <Switch>
                  <Route path="/" exact>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/" component={Login} />
                </Switch>
              </>
            )}
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
