import axios from 'axios'
import config from './../config'

	// //Offer
	// targetmarkets,
	// ytadTargeting,
	// wowmessage,
	// offer,
	// splinteroffer,
	// //Audience Generation
	// youtubeadScript,
	// youtubeadeditornotes,
	// tasksforproducer,
	// sendprompterad,
	// tasksforeditor,
	// postad,
	// //Lead Gen
	// headline,
	// trainingoutline,
	// outlinetoslides,
	// trainingScript,
	// thankyouscript,
	// sendprompter,
	// createfunnel,
	// //Lead Nurturing
	// confirmationEmail,
	// //Sales Conversion
	// salesScript,
	// roadmapbuilder,

	
//These still need to imported
	// //Product Development
	// summarize,
	// helloworld2,
	// example,
	// feature,
	// userStories,
	// acceptance,
	// requirements,
	// devtask,
	// developertask,
	// //Human Resources
	// jobad,





class Tools {
		constructor() { }
		TOOLS = []
	 
		getTools = async (userId) => {
			console.log('Tools');
			try {
				const options = {
					method: 'GET',
					url: `${config.baseURL}tools`,
					headers: {
						Accept: 'application/json',
						"Content-Type": 'application/json'
					},
					params: {
						userId: userId,
					},
				}	 
				const tools = await axios(options)

				console.log('Array of Tools', tools.data)
				return (tools.data)

			} catch (error) {
			console.log(error)
			}
		}
}

export default Tools
